import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/button";
import Layout from "../components/layout";
import { useMediaQuery } from "react-responsive";

const Container = styled.div(
  (props) => `
  height: calc(100vh - 8rem);
  padding: ${props.mobile ? "4rem 0 0 0" : "10rem"};
  display: flex;
  flex-direction: ${props.mobile ? "column" : ""};
  margin-top: 8rem;
  box-sizing: border-box;
`);

const ImgWrapper = styled.div(
  (props) => `
  width: ${props.mobile ? "100%" : "50%"};
  display: flex;
  align-items: center;
  padding: ${props.mobile ? "1rem" : ""};
  box-sizing: border-box;
`);

const ContentWrapper = styled.div(
  (props) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props.mobile ? "100%" : "50%"};
  padding: ${props.mobile ? "3.2rem" : "0 0 0 8rem"};
  box-sizing: border-box;
  align-items: ${props.mobile ? "center" : ""};

  h1{
    color: var(--ultramarine);
    font-weight: bold;
    text-align: ${props.mobile ? "center" : ""};
    font-size: ${props.mobile ? "2.8rem" : "4rem"};
  }

  p{
    font-size: 1.8rem;
    margin-bottom: 3.2rem;
    text-align: ${props.mobile ? "center" : ""};
  }
`);

const NotFoundPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });
  return (
    <Layout>
      <Container mobile={isMobile}>
        <ImgWrapper mobile={isMobile}>
          <StaticImage
            src="../images/error@3x.webp"
            alt="Error 404 image"
            className="error-img"
            placeholder="blurred"
          ></StaticImage>
        </ImgWrapper>
        <ContentWrapper mobile={isMobile}>
          <h1>Page not found</h1>
          <p>Sorry, we couldn’t find the page you are looking for</p>
          <Link to="/">
            <Button content="Explore Nearshore" font={isMobile ? "1.6rem" : "2rem"} bg="--accent"></Button>
          </Link>
        </ContentWrapper>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
